import { combineReducers } from '@reduxjs/toolkit';
import { reducer as socketReducer } from 'src/slices/socket';
import { reducer as serviceWorkerReducer } from 'src/slices/serviceWorker';

const rootReducer = combineReducers({
  socket: socketReducer,
  serviceWorker: serviceWorkerReducer,
});

export default rootReducer;
